import React, { useState, useEffect } from 'react'; 

// import style sheets 
import './style/App.css';
import './style/Gallery.css'; 
import './style/DarkModeLayout.css';  

// Import other components 
import DarkModeLayout from './components/DarkModeLayout'; 
import AboutContent from './components/about'; 
import PhotosContent from './components/photo';
import TopLeftCorner from './components/TopLeftCorner';  
import WritingsContent from './components/writings';  
import ThingsContent from './components/things'; 
import WWMContent from './components/wwm';

function App() {
  const [content, setContent] = useState('writings');
  console.log('setContent in App:', setContent); // Add this for debugging
  const [darkMode, setDarkMode] = useState(false); // Initialize dark mode 
  const [hideOnScroll, setHideOnScroll] = useState(false);

  // switching the morse code colors when clicking
  const toggleColor = () => {
    const morseCode = document.getElementById("morse-code");
    morseCode.style.color = morseCode.style.color === "rgb(26, 115, 232)" ? "grey" : "#1a73e8";
  };


  // Scroll detection to hide top-left corner and buttons
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 25) { // Adjust this value to trigger when scrolling past 30px
        setHideOnScroll(true);
      } else {
        setHideOnScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode); // Toggle dark mode state
  };

  return (
    <div className={`App ${darkMode ? 'dark-mode' : ''}`}>
      {/* Conditionally render the top-left corner and buttons based on dark mode */}
      {!darkMode && !hideOnScroll && <TopLeftCorner setContent={setContent}/>}  {/* Top left corner hidden on scroll or dark mode */}

      {/* Dark mode toggle button with emoji icon, always visible */}
      <div className="dark-mode-switch">
        <button onClick={toggleDarkMode}>
          <span role="img" aria-label="dark-mode-icon">
            {darkMode ? '🌜' : '🌞'}
          </span>
        </button>
      </div>

      <div className="content-container">
        {/* Conditionally render different layouts for dark mode */}
        {darkMode ? (
          <DarkModeLayout />
        ) : (
          <>
            {!hideOnScroll && (
              <div className="button-container">
                <button onClick={() => setContent('writings')}>Writings</button>
                <button onClick={() => setContent('things')}>Things</button>
                <button onClick={() => setContent('photos')}>Photos</button>
                <button onClick={() => setContent('about')}>About</button>
                <button onClick={() => window.location.href = 'mailto:nicopei321@gmail.com'}>Work With Me</button>
              </div>
            )}

            {content === 'writings' && <WritingsContent />}
            {content === 'things' && <ThingsContent />}
            {content === 'photos' && <PhotosContent />}
            {content === 'about' && <AboutContent toggleColor={toggleColor} />}
            {content === 'wwm' && <WWMContent/>}
          </>
        )}
      </div>
    </div>
  );
} 


export default App;
